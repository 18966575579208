import React from 'react';
import video from "../img/DevTribe01.mp4";

const Hero = () => {
    return (
        <section id="hero" className="hero section" >
            <div className="hero-bg">
                <video className="w-100" autoPlay loop muted >
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </section>
    );
}

export default Hero;
