import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import FooterAnimator from './FooterAnimator';
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import projectsData from './data.json';

const Projects = () => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);
  const [showMuteButton, setShowMuteButton] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch(error => {
        console.error("Error attempting to play video:", error);
      });
    }

    // Scroll to top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const toggleMute = () => {
    setIsMuted(!isMuted);
    videoRef.current.muted = !isMuted;
  };

  const handleMouseEnter = () => {
    setShowMuteButton(true);
  };

  const handleMouseLeave = () => {
    setShowMuteButton(false);
  };

  const handleViewDetails = (project) => {
    if (project.title === "Video Project") {
      navigate(`/project/3`);
    } else {
      navigate(`/project/${project.id}`);
    }
  };

  return (
    <>
      <section id='projects-section' className='projects-section'>
        <Fade top appear>
          <div className='container section-title' style={{ marginTop: '50px' }}>
            <h2>Explore Our Work</h2>
            <p style={{ fontSize: '20px' }}>
              Discover how we’ve transformed the online presence of impact-driven businesses, turning their stories into digital experiences.
            </p>
          </div>
        </Fade>

        <div className='container'>
          {projectsData.map((project, index) => (
            <React.Fragment key={project.id}>
              <article className='row gy-4 justify-content-between features-item projects-container'>
                <Fade appear>
                  <figure className='col-lg-5 image-container'>
                    <img src={require(`../img/${project.image}`)} alt={`Screenshot of ${project.title}`} className='img-fluid' />                  
                  </figure>
                </Fade>

                <Fade appear>
                  <div className='col-lg-5 text-container'>
                    <div className='caption'>
                      <h3 style={{ fontWeight: 'bold' }}>{project.title}</h3>
                      <p style={{ fontWeight: 'bold' }}>{project.description}</p>
                      <a className='view-project-link' href={`/project/${project.id}`} aria-label={`View details of ${project.title}`}>View Details</a>
                    </div>
                  </div>
                </Fade>
              </article>

              {/* Video Section */}
              {index === 3 && (
                <article className='row gy-4 justify-content-between features-item projects-container'>
                  <Fade appear>
                    <div className='col-lg-6 video-container' style={{ height: '500px' }}>
                      <video
                        ref={videoRef}
                        autoPlay
                        muted={isMuted}
                        controls
                        className='img-fluid'
                        style={{ height: '100%', objectFit: 'cover' }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        aria-label="Video showcasing our editing and animation skills"
                      >
                        <source src={require('../img/Sana.mp4')} type='video/mp4' />
                        Your browser does not support the video tag.
                      </video>
                      {showMuteButton && (
                        <button onClick={toggleMute} className='mute-button' aria-label={`Mute/Unmute video`}>
                          <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolumeUp} />
                        </button>
                      )}
                    </div>
                  </Fade>

                  <Fade appear>
                    <div className='col-lg-5 text-container'>
                      <div className='caption'>
                        <h3 style={{ fontWeight: 'bold' }}>Video Project</h3>
                        <p style={{ fontWeight: 'bold' }}>This is a sample video project that demonstrates the capabilities of our video editing and animation skills.</p>
                        <a className='view-project-link' href={`/project/3`} aria-label={`View details of Video Project`}>View Details</a>
                      </div>
                    </div>
                  </Fade>
                </article>
              )}
            </React.Fragment>
          ))}
        </div>
      </section>
      <FooterAnimator />
      <Footer />
    </>
  );
};

export default Projects;
