import React from 'react';
import details from "../img/features-1.jpg";
import Fade from 'react-reveal';
import web from "../img/webs.png";
import mobile from "../img/mobile.png";
import web1 from "../img/web1.png";
import art from "../img/3d.png";
import ui from "../img/UI.png";

const FeaturesDetail = () => {
    return (
        <section id='features-details' className='features-details section'>
            <div className='container'>

                <div className='row gy-4 justify-content-between features-item'>
                    <Fade left appear>
                        <div className='col-lg-5'>
                            <img src={web} alt='Web Development Service' className='img-fluid' />
                        </div>
                    </Fade>
                    <Fade right appear>
                        <div className='col-lg-5 d-flex align-items-center'>
                            <div className='content'>
                                <h2>
                                    Website Development
                                </h2>
                                <p>
                                    Crafting immersive web experiences with responsive designs, employing cutting-edge technologies for optimal performance and user engagement.
                                </p>
                                <ul>
                                    <li><i className="bi bi-easel flex-shrink-0"></i> Expertise in React JS, Next JS, Shopify, Wordpress and modern frontend frameworks like Bootstrap and Tailwind CSS.</li>
                                    <li><i className="bi bi-patch-check flex-shrink-0"></i> Proven track record of delivering high-quality, responsive web applications tailored to client needs.</li>
                                    <li><i className="bi bi-brightness-high flex-shrink-0"></i> Focus on responsive design and seamless user interactions.</li>
                                </ul>
                            </div>
                        </div>
                    </Fade>
                </div>

                <div className="row gy-4 justify-content-between features-item">
                    <div className="col-lg-5 d-flex align-items-center order-2 order-lg-1">
                        <Fade left appear>
                            <div className="content">
                                <h2>Mobile App Development</h2>
                                <p>
                                    Designing intuitive and feature-rich mobile applications for Android platforms, ensuring seamless functionality and delightful user experiences.
                                </p>
                                <ul>
                                    <li><i className="bi bi-easel flex-shrink-0"></i> Expertise in Android platforms.</li>
                                    <li><i className="bi bi-patch-check flex-shrink-0"></i> Proficiency in React Native.</li>
                                    <li><i className="bi bi-brightness-high flex-shrink-0"></i> Focus on intuitive UI/UX design and seamless functionality.</li>
                                </ul>
                            </div>
                        </Fade>
                    </div>

                    <Fade right appear>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <img src={web1} className="img-fluid" alt="Mobile App Development Service" />
                        </div>
                    </Fade>
                </div>

                <div className='row gy-4 justify-content-between features-item'>
                    <Fade left appear>
                        <div className='col-lg-5'>
                            <img src={ui} alt='UI/UX Design Service' className='img-fluid' />
                        </div>
                    </Fade>
                    <Fade right appear>
                        <div className='col-lg-5 d-flex align-items-center'>
                            <div className='content'>
                                <h2>
                                    UI/UX Designer
                                </h2>
                                <p>
                                    Designing user-centric interfaces that combine aesthetics with functionality, ensuring intuitive navigation and a seamless user experience.
                                </p>
                                <ul>
                                    <li><i className="bi bi-easel flex-shrink-0"></i> Expertise in user research and interaction design.</li>
                                    <li><i className="bi bi-patch-check flex-shrink-0"></i> Proficiency in tools like Adobe XD and Figma.</li>
                                    <li><i className="bi bi-brightness-high flex-shrink-0"></i> Focus on responsive design and user engagement.</li>
                                </ul>
                            </div>
                        </div>
                    </Fade>
                </div>

                <div className="row gy-4 justify-content-between features-item">
                    <div className="col-lg-5 d-flex align-items-center order-2 order-lg-1">
                        <Fade left appear>
                            <div className="content">
                                <h2>3D Artist</h2>
                                <p>
                                    Crafting visually stunning 3D environments using advanced techniques to deliver immersive and realistic digital experiences.
                                </p>
                                <ul>
                                    <li><i className="bi bi-easel flex-shrink-0"></i> Expertise in 3D lighting, texturing, layouting, and rendering.</li>
                                    <li><i className="bi bi-patch-check flex-shrink-0"></i> Proficiency in software like Unreal Engine, Blender, and Maya.</li>
                                    <li><i className="bi bi-brightness-high flex-shrink-0"></i> Focus on detailed craftsmanship and lifelike visuals.</li>
                                </ul>
                            </div>
                        </Fade>
                    </div>
                    <Fade right appear>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <img src={art} className="img-fluid" alt="3D Artist Service" />
                        </div>
                    </Fade>
                </div>
            </div>
        </section>
    )
}

export default FeaturesDetail;
