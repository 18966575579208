import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from "../img/logo-dev.png";
import { Modal, Button, Form } from 'react-bootstrap';
import Swal from 'sweetalert2'; // Importing SweetAlert2

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    webDevelopment: false,
    mobileAppDevelopment: false,
    uiUxDesign: false,
    animation: false,
    description: '',
    email: '',
    name: '',
  });

  const location = useLocation();
  const navigate = useNavigate();

  const handleScroll = () => {
    setScrolled(window.scrollY > 50);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    document.body.classList.toggle('mobile-nav-active');
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
    document.body.classList.remove('mobile-nav-active');
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const dropdownLinks = document.querySelectorAll('.navmenu .dropdown > a');

    const handleDropdownClick = (e) => {
      if (document.body.classList.contains('mobile-nav-active')) {
        e.preventDefault();
        e.currentTarget.nextElementSibling.classList.toggle('dropdown-active');
      }
    };

    dropdownLinks.forEach((dropdown) => {
      dropdown.addEventListener('click', handleDropdownClick);
    });

    return () => {
      dropdownLinks.forEach((dropdown) => {
        dropdown.removeEventListener('click', handleDropdownClick);
      });
    };
  }, [isMobileMenuOpen]);

  const handleNavigation = (event, sectionId) => {
    event.preventDefault();
    closeMobileMenu();

    if (location.pathname !== '/') {
      navigate(`/#${sectionId}`);
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        window.scrollTo({
          top: element.offsetTop - 50,
          behavior: 'smooth',
        });
      }
    }
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  
    Swal.fire({
      title: 'Thank You!',
      text: 'To send the email, you need to do one step more!',
      icon: 'info',
      confirmButtonText: 'OK',
      customClass: {
        icon: 'custom-swal-icon',
        confirmButton: 'custom-swal-button'
      }
    }).then(() => {
      const { email, name, description, webDevelopment, mobileAppDevelopment, uiUxDesign, animation } = formData;
  
      const subject = `Quote Request from ${name}`;
      const body = `Name: ${name}\nEmail: ${email}\n\nServices Requested:\n${webDevelopment ? "Web Development\n" : ""}${mobileAppDevelopment ? "Mobile App Development\n" : ""}${uiUxDesign ? "UI/UX Design\n" : ""}${animation ? "3D Animation\n" : ""}\nDescription:\n${description}`;
  
      const mailtoLink = `mailto:hr@thedevtribe.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  
      window.location.href = mailtoLink;
    });
  
    handleCloseModal(); // Close the modal after showing the SweetAlert
  };
  

  return (
    <div className='index-page'>
      <header id="header" className={`header d-flex align-items-center fixed-top ${scrolled ? 'header-scrolled' : ''}`}>
        <div className="container-fluid container-xl position-relative d-flex align-items-center">
          <Link to="/" className="logo d-flex align-items-center me-auto" onClick={closeMobileMenu}>
            <img src={logo} alt="MagicRealm Logo" />
          </Link>

          <nav id="navmenu" className={`navmenu ${isMobileMenuOpen ? 'mobile-nav-active' : ''}`}>
            <ul>
              <li><Link to="/" className={location.pathname === '/' ? 'active' : ''} onClick={closeMobileMenu}>Home</Link></li>
              <li><a href="/#about" onClick={(e) => handleNavigation(e, 'about')}>About</a></li>
              <li><a href="/#projectshome" onClick={(e) => handleNavigation(e, 'projectshome')}>Our Work</a></li>
              <li><a href="/#services" onClick={(e) => handleNavigation(e, 'services')}>Services</a></li>
              <li><a href="/#team" onClick={(e) => handleNavigation(e, 'team')}>Team</a></li>
              <li><Link to="/projects" className={location.pathname === '/projects' ? 'active' : ''} onClick={closeMobileMenu}>Projects</Link></li>
              <li><a href="/#contact" onClick={(e) => handleNavigation(e, 'contact')}>Contact</a></li>
            </ul>

            {isMobileMenuOpen ? (
              <i className="mobile-nav-toggle d-xl-none bi bi-x" onClick={toggleMobileMenu}></i>
            ) : (
              <i className="mobile-nav-toggle d-xl-none bi bi-list" onClick={toggleMobileMenu}></i>
            )}
          </nav>

          <a className="btn-getstarted" onClick={handleShowModal}>Get a Quote</a>
        </div>
      </header>

      <Modal show={showModal} onHide={handleCloseModal} dialogClassName="custom-modal">
        <Modal.Header >
          <Modal.Title style={{color:"#604f71"}}>Get a Quote</Modal.Title>
          <button type="button" className="btn-close-custom" onClick={handleCloseModal}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x">
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Transforming Ideas into Stunning Websites, Mobile Apps, and 3D Animations – Let's Build the Future Together!</p>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mt-3">
              <Form.Label style={{color:"#604f71", fontWeight:"bolder"}}>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="form-control-custom"
              />
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label style={{color:"#604f71", fontWeight:"bolder"}}>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="form-control-custom"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label style={{color:"#604f71", fontWeight:"bolder"}} className='mt-3'>What do you want?</Form.Label>
              <Form.Check
                type="checkbox"
                label="Web Development"
                name="webDevelopment"
                checked={formData.webDevelopment}
                onChange={handleChange}
                className="form-check-custom"
              />
              <Form.Check
                type="checkbox"
                label="Mobile App Development"
                name="mobileAppDevelopment"
                checked={formData.mobileAppDevelopment}
                onChange={handleChange}
                className="form-check-custom"
              />
              <Form.Check
                type="checkbox"
                label="UI/UX Design"
                name="uiUxDesign"
                checked={formData.uiUxDesign}
                onChange={handleChange}
                className="form-check-custom"
              />
              <Form.Check
                type="checkbox"
                label="3D Animation"
                name="animation"
                checked={formData.animation}
                onChange={handleChange}
                className="form-check-custom"
              />
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label style={{color:"#604f71", fontWeight:"bolder"}}>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={formData.description}
                onChange={handleChange}
                className="form-control-custom"
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3 send-button">
              Send
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Header;
