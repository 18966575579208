import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './projectdetails.css';
import projectsData from './data.json';
import FooterAnimator from './FooterAnimator';
import Footer from "./Footer";

const ProjectDetails = () => {
  const { projectId } = useParams();
  const project = projectsData.find(p => p.id === parseInt(projectId));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!project) {
    return <div>Loading...</div>;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true // Enable center mode for mobile devices
        }
      }
    ]
  };

  return (
    <>
      <section id='project-details' className='project-details'>
        <div className='container'>
          
          <Fade top appear>
            <figure className='main-image'>
              <img src={require(`../img/${project.detailsImage}`)} alt={`Main visual of ${project.title}`} className='img-fluid' />
             
            </figure>
          </Fade>

          <Fade bottom appear>
            <div className='section-title'>
              <h1>{project.title}</h1>
              <p>{project.description}</p>
            </div>
          </Fade>

          <div className='row gy-4 justify-content-between'>
            <Fade left appear>
              <div className='col-lg-12'>
                <div className='section-title'>
                  <h2>Technologies Used</h2>
                  <Slider {...settings} aria-label="Technology icons">
                    {project.technologies && project.technologies.map((tech, index) => (
                      <div key={index} className='technology-slide'>
                        <img src={require(`../img/tech-icons/${tech.icon}`)} alt={`${tech.name} icon`} className='tech-icon' width={100} height={100} /> 
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </Fade>
          </div>

          <Fade bottom appear>
            <div className='section-title'>
              <h2>Project Features</h2>
            </div>
          </Fade>

          <Fade right appear>
            <div className='row gy-4'>
              {project.features.map((feature, index) => (
                <div key={index} className='col-lg-6 col-md-6 col-sm-12 feature-item'>
                  <h3>{feature.title}</h3>
                  <div className='divider'></div>
                  <figure>
                    <img src={require(`../img/${feature.image}`)} alt={`Feature: ${feature.title}`} className='img-fluid' />
                
                  </figure>
                  <p>{feature.description}</p>
                </div>
              ))}
            </div>
          </Fade>

        </div>
      </section>
      <FooterAnimator />
      <Footer />
    </>
  );
};

export default ProjectDetails;
