import React from 'react';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import about from "../img/about-company-1.jpg";
import about1 from "../img/about-company-2.jpg";
import about2 from "../img/about-company-3.jpg";

const About = () => {
    return (
        <section id='about' className='about section'>
            <div className='container'>
                <div className='row gy-4'>

                    <Fade left appear>
                        <div className='col-lg-6 content'>
                            <p className='who-we-are'>
                                Who We Are
                            </p>
                            <h2>
                                Showcasing Our Creative Strategy to Unlock Your Business Potential
                            </h2>
                            <p>
                                Welcome to TheDevTribe, where we showcase our expertise in creative strategy and design. Explore our projects to see how we create impactful digital experiences that drive business growth and success.
                            </p>
                            <ul>
                                <li>
                                    <i className="bi bi-check-circle"></i>
                                    <span>Delivering high-quality work to ensure client satisfaction and project success.</span>
                                </li>
                                <li>
                                    <i className="bi bi-check-circle"></i>
                                    <span>Committed to creating user-friendly and visually appealing designs.</span>
                                </li>
                                <li>
                                    <i className="bi bi-check-circle"></i>
                                    <span>Focused on innovation and excellence, ensuring projects meet and exceed expectations.</span>
                                </li>
                            </ul>
                            <a href="/projects" className="read-more">
                                <span>Read More </span><i className="bi bi-arrow-right"></i>
                            </a>
                        </div>
                    </Fade>

                    <Fade right appear>
                        <div className='col-lg-6 about-images'>
                            <div className='row gy-4'>
                                <div className='col-lg-6'>
                                    <Zoom appear>
                                        <img src={about} alt='Creative strategy meeting at DevTribe' className='img-fluid' />
                                    </Zoom>
                                </div>

                                <div className='col-lg-6'>
                                    <div className='row gy-4'>
                                        <div className='col-lg-12'>
                                            <Zoom appear>
                                                <img src={about1} alt='Design brainstorming session' className='img-fluid' />
                                            </Zoom>
                                        </div>
                                        <div className="col-lg-12">
                                            <Zoom appear>
                                                <img src={about2} alt='Innovative project development at DevTribe' className="img-fluid" />
                                            </Zoom>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
        </section>
    );
};

export default About;
