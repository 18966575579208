import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';

const Faqs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFaq = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqItems = [
    {
      question: 'What technologies do you specialize in?',
      answer: 'We specialize in a range of frontend and backend technologies including React.js, React Native, Bootstrap, Material UI, RESTful APIs, MySQL, PHP, TypeScript, and Tailwind CSS.'
    },
    {
      question: 'Can you show examples of your previous work?',
      answer: 'Yes, you can view examples of our previous projects in the Projects section of this website. Each project includes a brief description, the technologies used, and features of every website.'
    },
    {
      question: 'Are you available for freelance work or contract opportunities?',
      answer: 'Yes, we are available for freelance and contract work. Please feel free to reach out to us through the Contact page with details about your project, and we will get back to you as soon as possible.'
    },
    {
      question: 'How do you stay up-to-date with the latest web development trends?',
      answer: 'We stay current with the latest web development trends by regularly reading industry blogs, participating in online courses and webinars. Our commitment to continuous learning ensures we bring the best and latest solutions to our client.'
    },
    {
      question: 'What is your approach to a new project?',
      answer: 'Our approach to a new project involves several key steps: Understanding Requirements: We start by gathering detailed requirements from the client to understand their vision and goals. Planning: Next, we create a project plan and timeline, outlining the tasks and milestones. Design: We then develop wireframes and mockups to visualize the structure and design. Development: Once the design is approved, we proceed with coding, ensuring clean and maintainable code. Testing: We rigorously test the website for functionality, usability, and performance across different devices and browsers. Deployment: Finally, we deploy the site and provide ongoing support and maintenance as needed.'
    },
  ];

  return (
    <section id='faq' className='faq section'>
      <Fade top appear>
        <header className='container section-title'>
          <h2>Frequently Asked Questions</h2>
        </header>
      </Fade>

      <Fade left appear>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-10'>
              <div className='faq-container'>
                {faqItems.map((item, index) => (
                  <article
                    key={index}
                    className={`faq-item ${activeIndex === index ? 'faq-active' : ''}`}
                    onClick={() => toggleFaq(index)}
                    role="button"
                    aria-expanded={activeIndex === index}
                    aria-controls={`faq-content-${index}`}
                    tabIndex="0"
                  >
                    <h3 className='faq-question'>{item.question}</h3>
                    <div
                      id={`faq-content-${index}`}
                      className={`faq-content ${activeIndex === index ? 'show' : ''}`}
                      aria-hidden={activeIndex !== index}
                    >
                      <p>{item.answer}</p>
                    </div>
                    <i
                      className={`faq-toggle bi ${activeIndex === index ? 'bi-chevron-up' : 'bi-chevron-down'}`}
                      aria-hidden="true"
                    ></i>
                  </article>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </section>
  );
};

export default Faqs;
