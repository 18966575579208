import React, { useRef, useState } from 'react';
import bgcontact from "../img/Group 392.svg";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import Fade from 'react-reveal/Fade';

const Contact = () => {
  const form = useRef();
  const [loading, setLoading] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    // Get values from form fields
    const userName = form.current["user_name"].value.trim();
    const userEmail = form.current["user_email"].value.trim();
    const userSubject = form.current["user_subject"].value.trim();
    const userPhone = form.current["user_phone"].value.trim();
    const message = form.current["message"].value.trim();

    // Check if required fields are empty
    if (!userName || !userEmail || !userSubject || !userPhone || !message) {
      // Show error message for empty fields
      Swal.fire({
        icon: "error",
        title: "Validation Error!",
        text: "Please fill in all required fields.",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: 'custom-confirm-button-class',
        },
      });
      return; // Prevent sending email if any required field is empty
    }

    // All required fields are filled, proceed with sending the email using EmailJS
    setLoading(true);
    emailjs.sendForm(
      "service_1w8vfpa",
      "template_jpfwpn2",
      form.current,
      "M10WZkqqJX7AQziBU"
    ).then(
      (result) => {
        setLoading(false);
        // Show success message after sending email
        Swal.fire({
          icon: "success",
          title: "Message Sent!",
          text: "Your message has been received. I will contact you soon.",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: 'custom-confirm-button-class',
          },
        });
        form.current.reset();
      },
      (error) => {
        setLoading(false);
        // Show error message if sending email fails
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "An error occurred while sending your message. Please try again later.",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: 'custom-confirm-button-class',
          },
        });
      }
    );
  };

  return (
    <section id="contact" className="contact section">
      <Fade top appear>
        <div className="container section-title">
          <h2>Contact</h2>
          <p>Having a project in mind or just want to say Hello? Feel free to reach out any time!</p>
        </div>
      </Fade>

      <div className="container">
        <div className="row gy-4">
          <Fade left appear>
            <div className="col-lg-6">
              <div className="info-item d-flex flex-column justify-content-center align-items-center">
                <i className="bi bi-geo-alt"></i>
                <h3>Address</h3>
                <p>Virtual Office</p>
              </div>
            </div>
          </Fade>

          <Fade top appear>
            <div className="col-lg-3 col-md-6">
              <div className="info-item d-flex flex-column justify-content-center align-items-center">
                <i className="bi bi-telephone"></i>
                <h3>Call Us</h3>
                <p>+92 3015513637</p>
              </div>
            </div>
          </Fade>

          <Fade right appear>
            <div className="col-lg-3 col-md-6">
              <div className="info-item d-flex flex-column justify-content-center align-items-center">
                <i className="bi bi-envelope"></i>
                <h3>Email Us</h3>
                <a href='mailto:hr@thedevtribe.com'>hr@thedevtribe.com</a>
              </div>
            </div>
          </Fade>
        </div>

        <div className="row gy-4 mt-1">
          <Fade left appear>
            <div className="col-lg-6 d-flex justify-content-center align-items-center">
              <img src={bgcontact} className="img-fluid" alt="Background" />
            </div>
          </Fade>

          <Fade right appear>
            <div className="col-lg-6">
              <form ref={form} onSubmit={sendEmail} className="php-email-form">
                <div className="row gy-4">
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="user_name"
                      className="form-control"
                      placeholder="Your Name"
                      required
                    />
                  </div>

                  <div className="col-md-6">
                    <input
                      type="email"
                      name="user_email"
                      className="form-control"
                      placeholder="Your Email"
                      required
                    />
                  </div>

                  <div className="col-md-6">
                    <input
                      type="text"
                      name="user_subject"
                      className="form-control"
                      placeholder="Subject"
                      required
                    />
                  </div>

                  <div className="col-md-6">
                    <input
                      type="text"
                      name="user_phone"
                      className="form-control"
                      placeholder="Phone Number"
                      required
                    />
                  </div>

                  <div className="col-md-12">
                    <textarea
                      name="message"
                      className="form-control"
                      rows="6"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>

                  <div className="col-md-12 text-center">
                    {loading && <div className="loading">Loading...</div>}
                    <button type="submit">Send Message</button>
                  </div>
                </div>
              </form>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default Contact;
