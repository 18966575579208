import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Projects from './Components/Projects';
import MainRoute from './MainRoute';
import ProjectDetails from './Components/ProjectDetails';
import Header from './Components/Header';

const ScrollToSection = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        setTimeout(() => { // Add timeout to allow render completion
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          window.scrollBy(0, -50); // Adjust for header
        }, 0);
      }
    }
  }, [pathname, hash]);

  return null;
};

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <ScrollToSection />
        <Routes>
          <Route path="/" element={<MainRoute />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/project/:projectId" element={<ProjectDetails />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
