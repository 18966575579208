import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const FooterAnimator = () => {
    const [currentWord, setCurrentWord] = useState(0);
    const words = ['excellence', 'creativity', 'technology', 'success'];

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentWord((prevWord) => (prevWord + 1) % words.length);
        }, 3000); 

        return () => clearInterval(intervalId);
    }, [words.length]);

    return (
        <footer id='footer-animator' className='footer-animator'>
            <div className="footer-section">
                <div className="container">
                    <h1>
                        Welcome to DevTribe, <br />
                        where innovation meets
                        <span id="changing-word" style={{ color: "#604f71" }}> {words[currentWord]}</span>!
                    </h1>
                    <div className="buttons">
                        <a href="mailto:hr@thedevtribe.com" className='learn' aria-label="Contact us via email">Get in touch</a>
                        <Link to="/projects" className='contact-button' aria-label="View our projects">Check out our work</Link>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default FooterAnimator;
