import React from 'react';
import Fade from 'react-reveal/Fade';
import team1 from "../img/natasha.jpeg";
import team2 from "../img/rafia.jpg";
import team3 from "../img/samreen1.jpeg";
import team4 from "../img/sana.jpeg";

const Team = () => {
    return (
        <>
            <section id='leadership-team' className='team section'>
                <Fade top appear>
                    <header className="container section-title">
                        <h2>Meet Our Leadership</h2>
                        <p>Guiding the vision and direction of our company!</p>
                    </header>
                </Fade>

                <div className="card-container container leadership">
                    <Fade left appear>
                        <article className="flip-card">
                            <div className="flip-card-inner">
                                <div className="flip-card-front">
                                    <img src={team2} alt="Rafia Aziz - CTO and Founder" className="team-image" />
                                    <h3>Rafia Aziz</h3>
                                    <p>CTO and Founder</p>
                                    <p>Frontend Developer</p>
                                </div>
                                <div className="flip-card-back">
                                    <h3 className='team-name'>Rafia Aziz</h3>
                                    <p>Creating dynamic and engaging web interfaces with a focus on performance and responsiveness.</p>
                                </div>
                            </div>
                        </article>
                    </Fade>

                    <Fade right appear>
                        <article className="flip-card">
                            <div className="flip-card-inner">
                                <div className="flip-card-front">
                                    <img src={team3} alt="Samreen Karim - CEO and Co-Founder" className="team-image" />
                                    <h3>Samreen Karim</h3>
                                    <p>CEO and Co-Founder</p>
                                    <p>Fullstack Developer</p>
                                </div>
                                <div className="flip-card-back">
                                    <h3 className='team-name'>Samreen Karim</h3>
                                    <p>Engineering robust and scalable end-to-end solutions, seamlessly integrating front and back-end technologies.</p>
                                </div>
                            </div>
                        </article>
                    </Fade>
                </div>
            </section>

            <section id='core-team' className='team section'>
                <Fade top appear>
                    <header className="container section-title">
                        <h2>Meet Our Team</h2>
                        <p>Discover the talented professionals driving our success!</p>
                    </header>
                </Fade>

                <div className="card-container container">
                    <Fade left appear>
                        <article className="flip-card">
                            <div className="flip-card-inner">
                                <div className="flip-card-front">
                                    <img src={team1} alt="Natasha - HR Specialist and UI/UX Designer" className="team-image" />
                                    <h3>Natasha</h3>
                                    <p>HR Specialist</p>
                                    <p>UI/UX Designer</p>
                                </div>
                                <div className="flip-card-back">
                                    <h3 className='team-name'>Natasha</h3>
                                    <p>Designing visually captivating and intuitively seamless user experiences.</p>
                                </div>
                            </div>
                        </article>
                    </Fade>

                    <Fade right appear>
                        <article className="flip-card">
                            <div className="flip-card-inner">
                                <div className="flip-card-front">
                                    <img src={team4} alt="Sana Farooq - 3D Artist" className="team-image" />
                                    <h3>Sana Farooq</h3>
                                    <p>Social Media Marketer</p>
                                    <p>3D Artist</p>
                                </div>
                                <div className="flip-card-back">
                                    <h3 className='team-name'>Sana Farooq</h3>
                                    <p>Transforming creative visions into immersive and breathtaking 3D experiences.</p>
                                </div>
                            </div>
                        </article>
                    </Fade>
                </div>
            </section>
        </>
    );
}

export default Team;
