import React from 'react';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import project1 from "../img/projectsam1.png";
import project2 from "../img/projectraf2.png";
import project3 from "../img/sanaproject.png";
import project4 from "../img/projectsam2.png";

const ProjectsHome = () => {
  return (
    <section id='projectshome' className='projectshome section'>
      <div className='container'>
        <Fade top appear>
          <header className='section-title'>
            <h2>Explore Our Work</h2>
          </header>
        </Fade>

        <div className='row g-0 justify-content-between features-item'>
          <Fade left appear>
            <article className='col-lg-5'>
              <img src={project1} alt='Screenshot of Laptop Selling Website' className='img-fluid' />
              <div className="caption">
                <h4>Laptop Selling Website: A modern e-commerce platform for buying and selling laptops.</h4>
                <Link to="/project/1" className="view-project-link" aria-label="View Laptop Selling Website Project">View Project</Link>
              </div>
            </article>
          </Fade>

          <Fade right appear>
            <article className='col-lg-5 mt-5 mt-lg-9'>
              <img src={project2} alt='Screenshot of Non-Profit Organization Website' className='img-fluid' />
              <div className="caption">
                <h4>Non-Profit Organization: A website dedicated to promoting social causes and volunteer opportunities.</h4>
                <Link to="/project/2" className="view-project-link" aria-label="View Non-Profit Organization Project">View Project</Link>
              </div>
            </article>
          </Fade>
        </div>

        <div className='row gy-3 justify-content-between features-item mt-0 mt-lg-0'>
          <Fade left appear>
            <article className='col-lg-5'>
              <img src={project3} alt='Screenshot of 3D Illustrations' className='img-fluid' />
              <div className="caption">
                <h4>3D Illustrations: High-quality 3D models and animations for various projects.</h4>
                <Link to="/project/3" className="view-project-link" aria-label="View 3D Illustrations Project">View Project</Link>
              </div>
            </article>
          </Fade>

          <Fade right appear>
            <article className='col-lg-5 mt-5 mt-lg-9'>
              <img src={project4} alt='Screenshot of Automotive Hub Website' className='img-fluid' />
              <div className="caption">
                <h4>Automotive Hub: A dynamic automotive hub presenting an extensive array of vehicles and parts.</h4>
                <Link to="/project/5" className="view-project-link" aria-label="View Automotive Hub Project">View Project</Link>
              </div>
            </article>
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default ProjectsHome;
