import React from 'react';
import Fade from 'react-reveal/Fade';

const Features = () => {
    return (
        <section id="featured-services" className='featured-services section'>
            <div className='container'>
                <div className='row gy-4'>

                    <Fade bottom appear>
                        <div className='col-lg-6'>
                            <div className='service-item d-flex'>
                                <div className='icon flex-shrink-0'>
                                    <i className='bi bi-browser-chrome' aria-hidden="true"></i>
                                </div>
                                <div>
                                    <h2 className='title'>
                                        <a href='#web-development' className='stretched-link'>Web Developer</a>
                                    </h2>
                                    <p className='description'>Expert web development services, including website creation, maintenance, and complex web application development, tailored to your business needs.</p>
                                </div>
                            </div>
                        </div>
                    </Fade>

                    <Fade bottom appear>
                        <div className='col-lg-6'>
                            <div className='service-item d-flex'>
                                <div className='icon flex-shrink-0'>
                                    <i className='bi bi-phone' aria-hidden="true"></i>
                                </div>
                                <div>
                                    <h2 className='title'>
                                        <a href='#mobile-app-development' className='stretched-link'>Mobile App Developer</a>
                                    </h2>
                                    <p className='description'>Professional mobile app development, from simple applications to complex, feature-rich solutions for iOS and Android platforms.</p>
                                </div>
                            </div>
                        </div>
                    </Fade>

                    <Fade bottom appear>
                        <div className='col-lg-6'>
                            <div className='service-item d-flex'>
                                <div className='icon flex-shrink-0'>
                                    <i className='bi bi-bar-chart' aria-hidden="true"></i>
                                </div>
                                <div>
                                    <h2 className='title'>
                                        <a href='#ui-ux-design' className='stretched-link'>UI/UX Design</a>
                                    </h2>
                                    <p className='description'>UI/UX design services to enhance user interaction and satisfaction with visually appealing and highly intuitive interfaces.</p>
                                </div>
                            </div>
                        </div>
                    </Fade>

                    <Fade bottom appear>
                        <div className='col-lg-6'>
                            <div className='service-item d-flex'>
                                <div className='icon flex-shrink-0'>
                                    <i className='bi bi-badge-3d' aria-hidden="true"></i>
                                </div>
                                <div>
                                    <h2 className='title'>
                                        <a href='#3d-design' className='stretched-link'>3D Artist</a>
                                    </h2>
                                    <p className='description'>3D art creation for visual effects, animations, and models, transforming ideas into immersive and captivating visual experiences.</p>
                                </div>
                            </div>
                        </div>
                    </Fade>

                </div>
            </div>
        </section>
    );
}

export default Features;
